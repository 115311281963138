import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { StaticContext, useHistory } from 'react-router'
import { Step } from '../types/Material.types'
import { UrlsEnum } from '../../../types/UrlsEnum'
import { Collapsible, Link, Text } from 'eva-react'
import { sendEvent } from '../../../infrastructure/track/track'
import StringUtils from '../../../utils/StringUtils'
import TipsSection from '../TipsSection/TipsSection'
import styles from './MaterialTabContent.module.scss'
import MaterialDownloadButton from '../../../components/MaterialDownloadButton/MaterialDownloadButton'
import CourseAnnouncement from '../CourseAnnouncement/CourseAnnouncement'

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = RouteComponentProps<{}, StaticContext, { content: Step }>

const MaterialTabContent: React.FC<Props> = ({ location }) => {
  const history = useHistory()
  const [extraMaterialButton, setExtraMaterialButton] = useState({})
  const step = useMemo(() => {
    return location.state ? location.state.content : null
  }, [location])

  useEffect(() => {
    return () => {
      setExtraMaterialButton({})
    }
  }, [location])

  const toggleButton = useCallback((buttonId: string, canOpen: boolean) => {
    setExtraMaterialButton((prev) => {
      prev[buttonId] = canOpen
      return { ...prev }
    })
  }, [])

  const tabFundamental2 = step?.track === 'Fund2'

  const renderLinks = function (step: Step) {
    return (
      <TipsSection
        video={step.video}
        guideFile={step.guideIRM}
        step={step.track}
      />
    )
  }

  return (
    <>
      <div className='container'>
        {step &&
          step.regions.map((region, key_region) => (
            <div className={`${styles.regionContainer}`} key={key_region} data-testid="text-p1-course-announcement">
             
            {region.courseTitle === 'Xperience Nova Escola' && (
              <CourseAnnouncement
                step={step.track}
                uniqueCourseLink={region.uniqueCourseLink}
                city={region.title}
              />
            )}
             
              {region.title === 'Materiais Complementares' && (
                <div id='section-xperience-extra'>
                  <Text bold className={`me-font-30`} color='peixe-boi-dark'>
                    {region.title}
                  </Text>
                </div>
              )}
              
              {region.title !== 'Materiais Complementares' && (
                <Text
                  id={`${StringUtils.normalizeString(region.title)}`}
                  bold
                  className={`me-font-24`}
                  color='peixe-boi-dark'
                >
                  {region.title}
                </Text>
              )}
              
              <Text className={`me-font-16`} color='peixe-boi-dark'>
                {region.subtitle}
              </Text>
             
              {region.units.map((unit, key_unit) => {
                const buttonId = `b${key_region}-${key_unit}_${unit.grade}`
                return (
                  <section
                    data-testid='collapsible-section'
                    className={styles.collapsibleSection}
                    key={buttonId}
                  >
                    <Collapsible
                      classNameLabel={styles.collapsibleText}
                      classNameContent={styles.collapsibleContent}
                      variant='box'
                      label={unit.label.toUpperCase()}
                      onOpen={() => toggleButton(buttonId, tabFundamental2)}
                      onClose={() => toggleButton(buttonId, false)}
                    >
                      {unit.content.map((content, key) => (
                        <div className={styles.content} key={key}>
                          <MaterialDownloadButton
                            src={content.resource}
                            contentTitle={content.title}
                            step={step.track}
                            region={region.title}
                            analyticsLabel={content.analyticsLabel}
                          />
                        </div>
                      ))}
                    </Collapsible>
                    {extraMaterialButton[buttonId] &&
                      region.title !== 'Materiais Complementares' && (
                        <div className={styles.extraButtonContainer}>
                          <a
                            data-testid='button-ExtraMaterial'
                            className='eva-btn-solid eva-btn-cinza-700 eva-btn-medium eva-margin-center'
                            onClick={() => {
                              sendEvent(
                                'Material Educacional',
                                'Click',
                                `Material Educacional: Click - ${step.track}-Download-${region.title}-${unit.analyticsLabelAudioGroup}`
                              )
                              history.push(
                                `${UrlsEnum.extraDownloads}/${region.region}/${unit.grade}`
                              )
                            }}
                          >
                            Áudios Complementares
                          </a>
                        </div>
                      )}
                  </section>
                )
              })}
              
              {step && step.track !== 'Fund2' && (
                region.title === 'Ceará' ||
                region.title === 'Amapá' ||
                region.title === 'Piauí' ||
                region.title === 'São Paulo') && (
                  <CourseAnnouncement
                    step={step.track}
                    uniqueCourseLink={region.uniqueCourseLink}
                    courses={region.courses}
                    city={region.title}
                  />
                )
              }

            </div>
          ))
        }

        {step && renderLinks(step)}
        
        {step && step.track === 'Fund2' && (
          <Text
            className={`container me-font-16 eva-margin-bottom-4`}
            color='peixe-boi-dark'
          >
            Confira também as{' '}
            <Link
              className={`me-font-16`}
              onClick={() =>
                sendEvent(
                  'Material Educacional',
                  'Click',
                  `Material Educacional: Click - ${step.track}-Download-MateriaisComplementares-sugestoesacoescontextopandemico`
                )
              }
              href='https://nova-escola-producao.s3.amazonaws.com/Book_Contexto_Pandemia.pdf'
            >
              nossas sugestões de ações pedagógicas para o contexto da pandemia
              da covid-19
            </Link>{' '}
            usando o Xperience Nova Escola
          </Text>
        )}

        <Text
          className={`me-font-16 ${styles.borderLinks}`}
          color='peixe-boi-dark'
          >
          <span className={`me-font-24 eva-text-bold eva-margin-bottom-2`}>
            Encontrou algum problema?
          </span>
          <br />
          É muito importante saber mais sobre suas impressões sobre o Material
          Educacional Nova Escola. <br />
          <u>
            <Link
              className={`me-font-16`}
              href='https://pt.surveymonkey.com/r/materialeducacional'
            >
              Caso tenha identificado algum problema conte para nós para que
              possamos corrigir!
            </Link>
            </u>
        </Text>
      
        {step && step.track === 'Fund2' && (
          <Text
            align='center'
            className={`me-font-20 eva-margin-bottom-4 eva-margin-top-4`}
            color='peixe-boi-dark'
          >
            Quer saber mais? Acesse o{' '}
            <u>
              <Link
                className={`me-font-20`}
                href='https://www.inglesnasescolas.org/'
              >
                Observatório para o Ensino da Língua Inglesa
              </Link>
            </u>
            , uma plataforma online voltada para professores, pesquisadores,
            técnicos e gestores de educação. Lá você encontra todas as ações
            desenvolvidas pelo programa Skills for Prosperity.
          </Text>
        )}
      </div>
    </>
  )
}

export default MaterialTabContent
