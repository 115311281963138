import React, { useEffect } from 'react'
import Header from './Header/Header'
import TeachersComment from './TeachersComment/TeachersComment'
import useApi from '../../hooks/api/useApi'
import { MaterialContentResponse } from './types/Material.types'
import MaterialTabNavigation from './MaterialTabNavigation/MaterialTabNavigation'
import { Text } from 'eva-react'
import TabSkeleton from '../../components/TabSkeleton/TabSkeleton'
import { useLocation } from 'react-router'
import Faq from '../home/Faq/Faq'

const MaterialScreen: React.FC = () => {
  const menuSize = 55
  const { hash: contentHash } = useLocation()
  const { payload, isLoading, fetchData } = useApi<MaterialContentResponse>(
    'get',
    '/assets/material.json'
  )

  useEffect(() => {
    if (contentHash) {
      const element = document.getElementById(contentHash.replace('#', ''))
      setTimeout(() => {
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top: element ? element.offsetTop - menuSize : 0
        })
      }, 100)
    }

    setTimeout(() => {
      document.body.classList.remove('has-hero')
      // eslint-disable-next-line
      const header = document.querySelector('#ane-main-header')!
      header.classList.add('padding-header')
    }, 100)
  }, [isLoading])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      <Header />
      <div
        className='container eva-margin-top-6 eva-margin-bottom-3'
        id='etapas'
      >
        <Text
          color='peixe-boi-dark'
          align='center'
          bold
          className={`me-font-42`}
        >
          Materiais Educacionais
          <br />
          por Etapas de Ensino
        </Text>
        <Text color='peixe-boi-dark' align='center' className={`me-font-24`}>
          Encontre o livro do professor e do aluno para usar em PDF a qualquer
          momento
        </Text>
      </div>
      {isLoading && <TabSkeleton />}
      {payload && (
        <>
          <MaterialTabNavigation material={payload} hash={contentHash} />
          <Faq faqInformations={payload.faqContent} />
        </>
      )}

      <TeachersComment />
    </>
  )
}

export default MaterialScreen
