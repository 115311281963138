import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { UrlsEnum } from '../../../types/UrlsEnum'
import TabBar from '../../../components/TabBar/TabBar'
import { MaterialContentResponse } from '../types/Material.types'
import MaterialTabContent from '../MaterialTabContent/MaterialTabContent'

type Props = {
  material: MaterialContentResponse
  hash?: string
}

const getTabURL = (title: string) => {
  switch (title) {
    case 'Ensino Fundamental 1':
      return UrlsEnum.tabFundamental1
    case 'Ensino Fundamental 2':
      return UrlsEnum.tabFundamental2
    default:
      return UrlsEnum.tabEducacaoInfantil
  }
}

const MaterialTabNavigation: React.FC<Props> = ({ material, hash }) => {
  const [initialTabSelected, setInitialTabSelected] = useState(0)
  const history = useHistory()

  const [currentTab, setCurrentTab] = useState(() => {
    // checagem para caso a url vier com barra no final para que reconheça a
    // url por exemplo /downloads/ensino-fundamental-1/ e remova o barra do
    // final em vez de redirecionar para rota padrão que é
    // /downloads/educacao-infantil

    const index = history.location.pathname === '/'

    if (!index && history.location.pathname.endsWith('/')) {
      return history.location.pathname.substring(
        0,
        window.location.pathname.length - 1
      )
    }

    return history.location.pathname
  })

  const tabs = material.steps.map((step) => {
    return {
      label: step.title,
      url: getTabURL(step.title),
      content: step
    }
  })

  useEffect(() => {
    const tab =
      tabs.find((tab) => tab.url === currentTab) || tabs[0]

    history.push({
      pathname: tab.url,
      hash,
      state: { content: tab.content }
    })
    setInitialTabSelected(
      tabs.findIndex((tab) => tab.url === currentTab)
    )
    return () => {}
  }, [currentTab])

  return (
    <>
      <TabBar
        initial={initialTabSelected}
        tabs={tabs.map((tab) => ({
          label: tab.label,
          onClick: () => {
            setCurrentTab(tab.url)
          }
        }))}
      />

      <>
        <Switch>
          <Route
            path={UrlsEnum.tabEducacaoInfantil}
            component={MaterialTabContent}
          />
          <Route
            path={UrlsEnum.tabFundamental1}
            component={MaterialTabContent}
          />
          <Route
            path={UrlsEnum.tabFundamental2}
            component={MaterialTabContent}
          />
        </Switch>
      </>
    </>
  )
}

export default MaterialTabNavigation
