import { DatalakeController } from '../../infrastructure/datalake/datalake.controller';
import { DownloadMaterialController } from '../download-material/download-material.controller';
import { LocationController } from '../location/location.controller'
import { LoginController } from '../login/login.controller';

export class MainController {
  private static instance: MainController;

  public readonly locationController: LocationController;
  public readonly loginController: LoginController;
  public readonly datalakeController: DatalakeController;
  public readonly downloadMaterialController: DownloadMaterialController;

  private constructor() {
    this.locationController = new LocationController();
    this.loginController = new LoginController();
    this.datalakeController = new DatalakeController();
    this.downloadMaterialController = new DownloadMaterialController();
  }

  public init() {
    this.loginController.init();
  }

  public static getInstance(): MainController {
    if(!MainController.instance) {
      MainController.instance = new MainController();
      MainController.instance.init();
    }
    return MainController.instance;
  }
}