import React, { useEffect, useMemo } from 'react'
import { Text } from 'eva-react'
import useApi from '../../hooks/api/useApi'
import { ExtraMaterialContentResponse } from './types/ExtraMaterial.types'
import SkeletonAudioCard from '../../components/ExtraMaterialCard/SkeletonAudioCard/SkeletonAudioCard'
import styles from './ExtraMaterialScreen.module.scss'
import { useHistory, useParams } from 'react-router-dom'
import { GradesEnum } from './types/GradesEnum'
import AudioGrid from './AudioGrid/AudioGrid'
import { PeriodsEnum } from './types/PeriodsEnum'
import { UrlsEnum } from '../../types/UrlsEnum'

type URLParams = {
  file: string
  grade: string
  region: 'NAC' | 'SP'
}

const ExtraMaterialScreen: React.FC = () => {
  const {
    payload,
    isLoading,
    fetchData
  } = useApi<ExtraMaterialContentResponse>('get', '/assets/extra-material.json')

  useEffect(() => window.scrollTo(0, 0), [])

  const { file, grade, region } = useParams<URLParams>()
  const history = useHistory()

  const filteredAudios = useMemo(() => {
    if (region === 'NAC' || region === 'SP') {
      return payload?.resources.filter((item) => {
        return (
          item.region === region && (region === 'SP' || item.grade === grade)
        )
      })
    }
  }, [payload, region])

  useEffect(() => {
    if (payload && region !== 'NAC' && region !== 'SP') {
      const qrcode = payload.resources.find((item) => {
        return item.qrcode === region
      })
      if (qrcode) {
        history.push(
          `${UrlsEnum.extraDownloads}/${qrcode.region}/${
            qrcode.region === 'SP' ? qrcode.period : qrcode.grade
          }/${qrcode.qrcode}`
        )
      }
    }
  }, [payload, history])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <div className='container'>
      <div className={`eva-margin-bottom-3 ${styles.screen}`}>
        <div className='col-lg-12'>
          <Text
            bold
            color='black'
            align='center'
            className={`${styles.title} me-font-40`}
          >
            Áudios complementares de inglês
            {region === 'NAC' && (
              <>
                {' | '} {GradesEnum[grade]}
              </>
            )}
          </Text>
          <Text
            color='black'
            align='center'
            className={`${styles.description} me-font-30`}
          >
            {
              'Clique em "Baixar áudio" para baixar o arquivo de áudio em seu computador ou celular e usá-lo de forma offline'
            }
          </Text>
        </div>
      </div>
      {isLoading && <SkeletonAudioCard />}
      {filteredAudios && region === 'NAC' && (
        <AudioGrid filteredAudios={filteredAudios} file={file} />
      )}
      {filteredAudios && region === 'SP' && (
        <div>
          {['6-ano', '7-ano', '8-ano', '9-ano'].map((gradeMap, gradeIndex) => (
            <div key={gradeIndex}>
              <AudioGrid
                filteredAudios={filteredAudios.filter(
                  (audio) => audio.grade === gradeMap && audio.period === grade
                )}
                file={file}
                periodTitle={`${PeriodsEnum[grade]} | ${GradesEnum[gradeMap]}`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ExtraMaterialScreen
