import React, { useContext, useEffect, createRef, useState } from 'react'
import styles from './DownloadButton.module.scss'
import AuthContext from '../../../infrastructure/auth/AuthContext'

type Props = {
  src: string
}

const DownloadButton: React.FC<Props> = ({ src }) => {
  const [canDownload, setCanDownload] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const node = createRef<HTMLAnchorElement>()

  const { doAuthentication } = useContext(AuthContext)

  useEffect(() => {
    if (node.current && canDownload) {
      node.current.click()
      setCanDownload(false)
    }
  }, [node, canDownload])

  const handleEnd = () => {
    setLoading(false)
  }
  const handleSuccess = () => {
    setCanDownload(true)
  }

  if (isLoading)
    return (
      <div className={styles.DownloadButton}>
        <i className={`fas fa-spinner ${styles.Icon} ${styles.rotating}`}></i>
      </div>
    )

  return (
    <div
      data-testid={'download-button'}
      className={styles.DownloadButton}
      onClick={() => {
        if (!canDownload) {
          setLoading(true)
          doAuthentication(handleSuccess, handleEnd)
        }
      }}
    >
      {canDownload && <a href={src} download ref={node} />}
      <i className={`fas fa-download ${styles.Icon}`}></i>
    </div>
  )
}

export default DownloadButton
