import React, { useEffect, useMemo } from 'react'
import { Text } from 'eva-react'
import Comment from './Comment/Comment'
import ArrowButton from './carousel/ArrowButton'
import useApi from '../../../hooks/api/useApi'
import styles from './TeachersComment.module.scss'
import AliceCarousel from 'react-alice-carousel'
import { TeachersCommentContentResponse } from './types/TeachersComment.types'
import 'react-alice-carousel/lib/alice-carousel.css'
import TeachersCommentSkeleton from '../../../components/TeachersCommentSkeleton/TeachersCommentSkeleton'

const TeachersComment: React.FC = () => {
  const { payload, isLoading, fetchData } =
    useApi<TeachersCommentContentResponse>(
      'get',
      '/assets/comentariosprofessores.json'
    )

  const items = useMemo(() => {
    return payload
      ? payload.comments.map((comment, index) => (
          <div
            aria-label='comentário de professor'
            className={styles.commentContainer}
            key={index}
          >
            <Comment {...comment} />
          </div>
        ))
      : []
  }, [payload])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <section className={styles.container}>
      <div className='container'>
        <div className={styles.header}>
          <Text
            color='peixe-boi-dark'
            align='center'
            className={`me-font-42`}
            bold
          >
            Veja o que já falaram sobre o material!
          </Text>
          <Text color='peixe-boi-dark' align='center' className={`me-font-16`}>
            Professores e gestores públicos que já aprovaram o conteúdo
          </Text>
        </div>
        <div className={styles.comments}>
          {isLoading && <TeachersCommentSkeleton />}
          {payload && (
            <AliceCarousel
              disableDotsControls={true}
              responsive={{
                0: { items: 1 },
                700: { items: 2 },
                940: { items: 3 },
                1120: { items: 4 }
              }}
              renderPrevButton={({ isDisabled }) => (
                <div className={styles.arrowButton}>
                  <ArrowButton disabled={isDisabled} orientation='left' />
                </div>
              )}
              renderNextButton={({ isDisabled }) => (
                <div className={styles.arrowButton}>
                  <ArrowButton disabled={isDisabled} orientation='right' />
                </div>
              )}
              mouseTracking
              items={items}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default TeachersComment
