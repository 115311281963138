import React, { useContext } from 'react'
import styles from './MaterialDownloadButton.module.scss'
import AuthContext from '../../infrastructure/auth/AuthContext'
import { UrlsEnum } from '../../types/UrlsEnum'
import { useHistory } from 'react-router-dom'
import { sendEvent } from '../../infrastructure/track/track'
import { Text } from 'eva-react'
import { MainController } from '../../app/main/main.controller'

type Props = {
  src: string
  contentTitle?: string
  step: string
  region: string,
  analyticsLabel: string
}

const MaterialDownloadButton: React.FC<Props> = ({
  src,
  contentTitle,
  step,
  region,
  analyticsLabel
}) => {
  const { datalakeController } = MainController.getInstance()
  const history = useHistory()
  const { userIsLoggedIn } = useContext(AuthContext)

  const handleDivDownloadMaterialClick = () => {
    const handleResolve = async () => {
      await sendEvent('Material Educacional', 'Click', `Material Educacional: Click - ${step}-Download-${region}-${analyticsLabel}`)
      await datalakeController.send({ PDF: src, acao: 'DownloadMENE' })
    }

    const handleReject = () => {
      const url = `${UrlsEnum.loginOrRegister}/${contentTitle}/${region}/${step}`
      history.push(url)
    }

    userIsLoggedIn(handleResolve, handleReject)
  }

  return (
    <a
      data-testid="material-download-button"
      className={styles.content}
      onClick={handleDivDownloadMaterialClick}
      href={src}
      target="_blank"
      rel="noreferrer noopener"
      download
    >
      <Text color="black" className="me-font-16">
        {contentTitle}
      </Text>
      <i className={`fas fa-download ${styles.downloadIcon}`}></i>
    </a>
  )
}

export default MaterialDownloadButton
