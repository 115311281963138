import React, { useEffect } from 'react'
import amplitude from 'amplitude-js'
import { Router, Route, Switch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import HomeScreen from './modules/home/HomeScreen'
import MaterialScreen from './modules/material/MaterialScreen'
import ExtraMaterialScreen from './modules/extra-material/ExtraMaterialScreen'
import LoginOrRegisterScreen from './modules/login-or-register/LoginOrRegisterScreen'
import { UrlsEnum } from './types/UrlsEnum'
import styles from './App.module.scss'

import AuthProvider from './infrastructure/auth/AuthProvider'
import './resources/grid.scss'
import './resources/general.scss'
import 'eva-react/dist/index.css'
import './resources/fonts.scss'
import { MainController } from './app/main/main.controller'

amplitude.getInstance().init('10f423a88f9ad4b94cba09e3c5e33e9d')

const App: React.FC = () => {
  const { locationController } = MainController.getInstance();
  const history = useHistory();
  useEffect(() => history.listen(locationController.setLocation.bind(locationController)),[history]) ;
  return (
    <AuthProvider authURL='https://account.novaescola.org.br/profile'>
      <div className={styles.container}>
        <Router history={history}>
          <Switch>
            <Route path={`${UrlsEnum.extraDownloads}/:region?/:grade?/:file?`}>
              <ExtraMaterialScreen />
            </Route>
            <Route path={UrlsEnum.downloads}>
              <MaterialScreen />
            </Route>
            <Route
              path={`${UrlsEnum.loginOrRegister}/:contentTitle/:region/:step`}
            >
              <LoginOrRegisterScreen />
            </Route>
            <Route path={`${UrlsEnum.home}`}>
              <HomeScreen />
            </Route>
          </Switch>
        </Router>
      </div>
    </AuthProvider>
  )
}

export default App
