import React, { SyntheticEvent } from 'react'
// import { colors } from 'eva-react'

import styles from './PlayPauseButton.module.scss'

type Props = {
  handleMusicPlay: (e: SyntheticEvent<Element, Event>) => void
  isPlaying: boolean | undefined
}

const PlayPauseButton: React.FC<Props> = ({ handleMusicPlay, isPlaying }) => {
  return (
    <div
      onClick={(e) => {
        handleMusicPlay(e)
      }}
      className={styles.playPauseContainer}
    >
      {isPlaying ? (
        <i
          className='fas fa-pause-circle'
          aria-label='botão pause'
          style={{color: "#1c5d33"}}
        ></i>
      ) : (
        <i
          aria-label='botão play'
          className='fas fa-play-circle'
          style={{color: "#1c5d33"}}
        ></i>
      )}
    </div>
  )
}

export default PlayPauseButton
