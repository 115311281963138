import React from 'react'
import { sendEvent } from '../../../infrastructure/track/track'
import amplitude from 'amplitude-js'
import styles from './CarouselVersions.module.scss'
import { Text } from 'eva-react'
import { Book } from '../../../../src/modules/material/types/Material.types'

const amplitudeDataLayer = 'dataLayerMXP'

type Props = {
  carouselInformations: Book[]
}

const CarouselVersions: React.FC<Props> = ({ carouselInformations }) => {
  return (
    <section
      className={`${styles.CarouselVersions} container-fluid eva-bg-cinza-700`}
    >
      <div className='container'>
        <Text
          bold
          align='center'
          className={`${styles.title} eva-color-branco eva-margin-bottom-1`}
        >
          Materiais que são a cara da sua turma
        </Text>
        <Text
          align='center'
          className={`${styles.subtitle} eva-color-branco eva-margin-bottom-4`}
        >
          Conheça as coleções criadas especialmente para Amapá, Ceará, Mato
          Grosso do Sul, Paraná, Pernambuco e São Paulo, alinhadas ao currículo
          de cada estado, ou utilize o material para todos os professores.
        </Text>

        <div className='eva-main-carrossel swiper-container swiper-multiple'>
          <div className='swiper-wrapper'>
            {carouselInformations?.map((value, index) => {
              return (
                <div className='swiper-slide' key={index}>
                  <div className='eva-card eva-bg-white'>
                    <a href={value.urlCard}>
                      <div className='eva-card-img'>
                        <div className='eva-card-bg'></div>
                        <img src={value.carouselImage} className='card-img' />
                      </div>
                    </a>
                    <div className={`${styles.modifyCardInfo} eva-card-info`}>
                      <a
                        href={value.urlCard}
                        onClick={() => {
                          sendEvent(
                            'Material Educacional',
                            'Click',
                            `LP-MENE-CARD-${value.clickEvent}-QUEROBAIXAR`
                          )
                          amplitude
                            .getInstance()
                            .logEvent(
                              `Material Educacional: CLICK - LP-MENE-CARD-${value.clickEvent}-QUEROBAIXAR`,
                              amplitudeDataLayer
                            )
                        }}
                      >
                        <p className='eva-card-sutitle'>{value.label}</p>
                        <p
                          className={`${styles.modifyTitle} eva-card-title eva-text-bold`}
                        >
                          {value.title}
                        </p>
                        <p
                          className={`${styles.modifyDescription} eva-card-description`}
                        >
                          {value.for}
                        </p>
                      </a>
                      <a
                        href={value.urlCard}
                        type='button'
                        className='eva-btn-solid eva-btn-cinza-700 eva-btn-medium eva-btn-full eva-margin-top-2'
                        onClick={() => {
                          sendEvent(
                            'Material Educacional',
                            'Click',
                            `LP-MENE-CARD-${value.clickEvent}-QUEROBAIXAR`
                          )
                          amplitude
                            .getInstance()
                            .logEvent(
                              `Material Educacional: CLICK - LP-MENE-CARD-${value.clickEvent}-QUEROBAIXAR`,
                              amplitudeDataLayer
                            )
                        }}
                      >
                        Quero baixar
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className='swiper-button-prev'>
            <span className='eva-btn-icon eva-btn-outline-branco eva-btn-medium'>
              <i className='fas fa-arrow-left'></i>
            </span>
          </div>
          <div className='swiper-button-next'>
            <span className='eva-btn-icon eva-btn-outline-branco eva-btn-medium'>
              <i className='fas fa-arrow-right'></i>
            </span>
          </div>
        </div>

        <div className={styles.carouselExtraInfo}>
          <Text
            bold
            align='center'
            className={`${styles.text} eva-color-branco eva-margin-bottom-2`}
          >
            Se você faz parte da Secretaria de Educação da sua cidade e tem
            interesse em ter um material educacional, conte para nós!
          </Text>

          <a
            href='https://docs.google.com/forms/d/e/1FAIpQLScOAriJuQg_5XIwecM_ETpjL-7sRwy4l7I-bPrB8N-ECbijFA/viewform'
            className='eva-btn-outline-branco eva-btn-large eva-margin-center'
            onClick={() => {
              sendEvent(
                'Material Educacional',
                'Click',
                `LP-MENE-BANNER-SECRETARIA-QUEROMECADASTRAR`
              )
              amplitude
                .getInstance()
                .logEvent(
                  'Material Educacional: CLICK - LP-MENE-BANNER-SECRETARIA-QUEROMECADASTRAR',
                  amplitudeDataLayer
                )
            }}
          >
            Quero me cadastrar
          </a>
        </div>
      </div>
    </section>
  )
}

export default CarouselVersions
