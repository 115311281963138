import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styles from './SkeletonAudioCard.module.scss'

const SkeletonAudioCard: React.FC = () => {
  return (
    <div
      className={`col-12 col-md-4 col-sm-6 ${styles.audioCard}`}
      data-testid='SkeletonAudioCard'
    >
      <div
        className={`eva-border-peixe-boi-lighter border-radius-4 ${styles.audioContent}`}
      >
        <div className='eva-margin-bottom-1'>
          <Skeleton count={2} />
        </div>
        <Skeleton height={164} />
      </div>
    </div>
  )
}

export default SkeletonAudioCard
