import React from 'react'
import { sendEvent } from '../../../infrastructure/track/track'
import amplitude from 'amplitude-js'
import styles from './Faq.module.scss'
import { Text, Collapsible } from 'eva-react'
import { Faq } from '../../../../src/modules/material/types/Material.types'

const amplitudeDataLayer = 'dataLayerMXP'

type Props = {
  faqInformations: Faq[]
}

const FaqComponent: React.FC<Props> = ({ faqInformations }) => {
  return (
    <section className={`${styles.Faq} container-fluid eva-bg-me-100`}>
      <div className='container'>
        <Text
          bold
          align='center'
          className={`${styles.title} eva-color-cinza-700 eva-margin-bottom-1`}
        >
          Dúvidas frequentes
        </Text>
        <Text
          align='center'
          className={`${styles.subtitle} eva-color-cinza-700 eva-margin-bottom-4`}
        >
          Tire suas dúvidas sobre esse projeto.
        </Text>

        {faqInformations?.map((value, index) => {
          return (
            <div
              data-testid='collapsible-section'
              className={styles.collapsibleSection}
              key={index}
            >
              <Collapsible
                classNameLabel={styles.collapsibleText}
                classNameContent={styles.collapsibleContent}
                variant='box'
                label={value.question}
              >
                <div>{value.answer}</div>
              </Collapsible>
            </div>
          )
        })}
        <a
          href='https://novaescola.zendesk.com/hc/pt-br/categories/360005606412-Nova-Escola-Material-Educacional'
          className='eva-btn-solid eva-btn-cinza-700 eva-btn-medium eva-margin-center eva-margin-top-4'
          onClick={() => {
            sendEvent(
              'Material Educacional',
              'Click',
              'LP-MENE-BANNER-DUVIDASFREQUENTES-OUTRASDUVIDAS'
            )
            amplitude
              .getInstance()
              .logEvent(
                'Material Educacional: CLICK - Material Educacional: CLICK - LP-MENE-BANNER-DUVIDASFREQUENTES-OUTRASDUVIDAS',
                amplitudeDataLayer
              )
          }}
        >
          Veja outras dúvidas
        </a>
      </div>
    </section>
  )
}

export default FaqComponent
