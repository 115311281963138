import { getCookie, setCookie } from "../../infrastructure/cookies/cookies";

export class LoginController {
  public readonly bearerTokenName: string;
  public readonly redirectCookieName: string;

  public constructor() {
    this.bearerTokenName = 'Bearer';
    this.redirectCookieName = 'ane_redirect';
  }

  public init() {
    this.setRedirectCookie('');
  }

  public getLoginToken(): string {
    return getCookie(this.bearerTokenName);
  }

  public isLogged(): boolean {
    const bearer = getCookie(this.bearerTokenName);
    if (!bearer) {
      return false;
    }
    return true;
  }

  public setRedirectCookie(redirectUrl: string) {
    const fullUrl = `${window.location.origin}${redirectUrl}`;
    if(fullUrl.indexOf('loginOrRegister') === -1) {
      setCookie(this.redirectCookieName, fullUrl);
    }
  }
}