import React from 'react'
import { Link, Text } from 'eva-react'
import styles from './TipsSection.module.scss'
import { sendEvent } from '../../../infrastructure/track/track'

type Props = {
  video: string
  guideFile: string
  step: string
}

const TipsSection: React.FC<Props> = ({ video, guideFile, step }) => {
  return (
    <section
      className={`container ${styles.pdfvideosection}`}
      data-testid='pdf-video-section'
    >
      <Text className={`me-font-16 `} color='peixe-boi-dark'>
        <span className={`me-font-24 eva-text-bold eva-margin-bottom-2`}>
          Confira o Guia de Dicas e Inspirações para um Planejamento Pedagógico
          Inclusivo.
        </span>
        <br />
        Dicas para que você planeje considerando a diversidade em sua turma,
        contemplando todos os estudantes, com e sem deficiência.
        <br />
        <u>
          <Link
            className={`me-font-16`}
            onClick={() =>
              sendEvent(
                'Material Educacional',
                'Click',
                `Material Educacional: Click - ${step}-video-MateriaisComplementares-videointrodutorioplanejamentoinclusivo`
              )
            }
            href={video}
          >
            Confira o vídeo introdutório
          </Link>
        </u>{' '}
        e{' '}
        <u>
          <Link
            className={`me-font-16`}
            onClick={() =>
              sendEvent(
                'Material Educacional',
                'Click',
                `Material Educacional: Click - ${step}-Download-MateriaisComplementares-guiaplanejamentoinclusivo`
              )
            }
            href={guideFile}
          >
            baixe o guia com mais informações.
          </Link>
        </u>
      </Text>
    </section>
  )
}

export default TipsSection
