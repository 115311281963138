import React, { useRef, SyntheticEvent, useState } from 'react'
import H5AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import PlayPauseButton from '../PlayPauseButton/PlayPauseButton'
import DownloadButton from '../DownloadButton/DownloadButton'
import styles from './AudioMedia.module.scss'
import './AudioPlayer.scss'
import RepeatButton from '../RepeatButton/RepeatButton'
import { Text } from 'eva-react'

type Props = {
  src: string
  onHandlePlay: (media: H5AudioPlayer | null) => void
}

const AudioMedia: React.FC<Props> = ({ src, onHandlePlay }) => {
  const [hasEnded, setHasEnded] = useState(false)
  const [isPlaying, setPlaying] = useState(false)

  const playerRef = useRef<H5AudioPlayer>(null)

  const handleTogglePayer = (e: SyntheticEvent<Element, Event>) => {
    playerRef.current?.togglePlay(e)
  }

  const Header = () => {
    return (
      <>
        <div className={styles.audioMedia}>
          {hasEnded ? (
            <>
              <RepeatButton
                handleMusicRepeat={() => setHasEnded(false)}
                handleTogglePayer={handleTogglePayer}
              />
              <Text
                bold
                align='center'
                className={`${styles.audioText} me-font-16 `}
              >
                Repetir áudio
              </Text>
            </>
          ) : (
            <>
              <PlayPauseButton
                handleMusicPlay={handleTogglePayer}
                isPlaying={isPlaying}
              />
              <Text
                bold
                align='center'
                className={`${styles.audioText} me-font-16`}
              >
                Ouvir áudio
              </Text>
            </>
          )}
        </div>
        <div className={styles.audioMedia}>
          <DownloadButton src={src} />
          <Text
            bold
            align='center'
            color='acai'
            className={`${styles.audioText} me-font-16`}
          >
            Baixar áudio
          </Text>
        </div>
      </>
    )
  }

  return (
    <H5AudioPlayer
      ref={playerRef}
      header={<Header />}
      src={src}
      className={`${styles.evaBorderOceanoAtlanticoLight}`}
      showSkipControls={false}
      showJumpControls={false}
      customControlsSection={[]}
      customProgressBarSection={[
        RHAP_UI.PROGRESS_BAR,
        RHAP_UI.CURRENT_TIME,
        RHAP_UI.DURATION
      ]}
      onPlay={() => {
        setPlaying(true)
        onHandlePlay(playerRef.current)
      }}
      onPause={() => setPlaying(false)}
      onEnded={() => setHasEnded(true)}
      onCanPlay={() => setHasEnded(false)}
    />
  )
}

export default AudioMedia
