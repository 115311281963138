import React from 'react'
import Skeleton from 'react-loading-skeleton'

const TabSkeleton: React.FC = () => {
  return (
    <div
      className='container'
      data-testid='tab-skeleton'
    >
      <div className='eva-margin-bottom-3'>
        <Skeleton height={50}></Skeleton>
      </div>
      <div className='eva-margin-bottom-1'>
        <Skeleton height={35} width={150}></Skeleton>
      </div>
      <div className='eva-margin-bottom-1'>
        <Skeleton height={25} width={250}></Skeleton>
      </div>
    </div>
  )
}

export default TabSkeleton
