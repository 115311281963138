import React from 'react'
import css from 'clsx'
import styles from './ArrowButton.module.scss'

type Props = {
  disabled: boolean
  orientation: 'left' | 'right'
}

const ArrowButton: React.FC<Props> = ({ disabled, orientation }) => {
  return (
    <div
      className={css(
        orientation === 'right' ? styles.right : styles.left,
        disabled && styles.disabled,
        'eva-btn-medium',
        'eva-btn-outline',
        {
          'eva-btn-disabled': disabled
        }
      )}
    >
      <i className={css('fas', `fa-arrow-${orientation}`, styles.icon)} />
    </div>
  )
}

export default ArrowButton
