import { createContext } from 'react'

export interface IAuthContext {
  doAuthentication: (
    onAuthSuccess: () => void,
    onAuthEnd: () => void
  ) => void

  userIsLoggedIn: (
    onSuccess: () => void,
    onNotSuccess: () => void
  ) => void
}

export default createContext<IAuthContext>({
  doAuthentication: () => {},
  userIsLoggedIn: () => {},
})
