import React, { createRef, useEffect, useState } from 'react'
import AudioCard from '../../../components/ExtraMaterialCard/AudioCard/AudioCard'
import H5AudioPlayer from 'react-h5-audio-player'
import { Resource } from '../types/ExtraMaterial.types'
import { Text } from 'eva-react'

type Props = {
  filteredAudios: Resource[]
  file: string
  periodTitle?: string
}

const MIN_RESOURCES = 6

let currentMedia: H5AudioPlayer | null = null
let skipFocus = false

const AudioGrid: React.FC<Props> = ({ filteredAudios, file, periodTitle }) => {
  const [limit, setLimit] = useState(MIN_RESOURCES)
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [resourceFile, setResourceFile] = useState<number>(-1)
  const resourceRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (filteredAudios) {
      const resourcesLen = filteredAudios.length
      setShowLoadMore(resourcesLen > MIN_RESOURCES && limit < resourcesLen)
    }

    if (file && filteredAudios) {
      const selectedAudioIndex = filteredAudios.findIndex((resource) => {
        return resource.qrcode === file
      })

      if (selectedAudioIndex >= 0) {
        setLimit((prev) => {
          return Math.max(
            prev,
            3 + Math.ceil(selectedAudioIndex / MIN_RESOURCES) * MIN_RESOURCES
          )
        })
        setResourceFile(selectedAudioIndex)
      }
    }

    if (filteredAudios && !skipFocus && resourceRef.current) {
      setTimeout(() => {
        skipFocus = false
        resourceRef.current?.focus()
      }, 100)
    }

    return () => {}
  }, [filteredAudios, resourceRef, file])

  const onHandlePlay = (media: H5AudioPlayer | null) => {
    if (currentMedia && media !== currentMedia) {
      currentMedia.audio.current?.pause()
    }
    currentMedia = media
  }

  return (
    <>
      {periodTitle && filteredAudios.length > 0 && (
        <Text
          data-testid='period-grade-title'
          color='black'
          bold
          className='eva-margin-top-1 eva-margin-bottom-2 me-font-30'
        >
          {periodTitle}
        </Text>
      )}
      <div className='row eva-margin-bottom-4'>
        {filteredAudios.slice(0, limit).map((resource, r_idx) => {
          return (
            <AudioCard
              key={r_idx}
              ref={r_idx === resourceFile ? resourceRef : null}
              onHandlePlay={onHandlePlay}
              title={[
                resource.unit,
                resource.lesson,
                resource.activity,
                resource.section
              ]
                .filter(Boolean)
                .join(' - ')}
              file={resource.file}
              qrcode={resource.qrcode}
            />
          )
        })}
        {showLoadMore && (
          <div className='col-12 eva-margin-top-1 eva-margin-bottom-2'>
            <button
              className='eva-btn-outline eva-btn-medium eva-margin-center'
              data-testid="button-LoadMore"
              onClick={() => {
                skipFocus = true
                setLimit((prev) => prev + MIN_RESOURCES)
              }}
            >
              Mais Unidades
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default AudioGrid
