import React from 'react'
import { Text } from 'eva-react'
import styles from './Comment.module.scss'

type Props = {
  photo: string
  author: string
  profession: string
  message: string
}

const Comment: React.FC<Props> = ({ photo, author, profession, message }) => {
  return (
    <div className={styles.comment}>
      <img src={process.env.PUBLIC_URL + '/assets/professores/' + photo}></img>
      <Text
        className={`${styles.commentName} me-font-20`}
        align='center'
        bold
        color='peixe-boi-dark'
      >
        {author}
      </Text>
      <Text align='center' className='me-font-16' color='peixe-boi-dark'>
        {message}
      </Text>
      <div>
        <Text
          align='center'
          color='peixe-boi-dark'
          italic
          className='eva-margin-top-3 me-font-16'
        >
          {profession}
        </Text>
      </div>
    </div>
  )
}

export default Comment
