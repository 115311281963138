import React from 'react'
import { Text } from 'eva-react'
import styles from './CourseAnnouncement.module.scss'
import { sendEvent } from '../../../infrastructure/track/track'

export type Course = {
  grade: string
  url: string
}

type CourseAnnouncementProps = {
  city: string
  uniqueCourseLink?: string
  courses?: Course[]
  step: string
}

const cityTitle = (city: string) => {
  if (city === 'São Paulo')
    return `Curso Online para Uso do Material Educacional de ${city}`
  return `Curso Online para Uso do Material Educacional do ${city}`
}

const CourseAnnouncement: React.FC<CourseAnnouncementProps> = ({
  city,
  uniqueCourseLink,
  courses,
  step
}) => {

  return (
    <>
    {step === 'EI' && (
      <div className={styles.container}>
        <Text
          className={styles.p1}
          align='center'
          size={24}
        >
          {cityTitle(city)}
        </Text>
        <Text align='center' className='eva-color-cinza-700'>
          {city === 'Ceará' && (
            `Faça a formação, com certificado, e descubra como usar o material de
            Educação Infantil para promover melhores experiências às crianças`
          )}

          {city === 'São Paulo' && (
            `Faça a formação, com certificado, e descubra como usar o material de Educação Infantil para promover melhores experiências às crianças. Curso autorizado pela Portaria do Coordenador EFAPE de 24/09/2021, publicada no Diário Oficial do Estado de São Paulo – DOE-SP – de 25/09/2021, Caderno Executivo I, pág. 55`
          )}
        </Text>
        <div className={styles.buttonContainer}>
          <a
            href={uniqueCourseLink ? uniqueCourseLink : ''}
            className='eva-btn-solid eva-btn-cinza-700 eva-btn-medium eva-margin-center'
            onClick={() => {
              sendEvent(
                'Material Educacional',
                'Click',
                `Material Educacional: Click - ${step}-${city}-FACAGRATIS`
              )
            }}
          >
            Faça grátis
          </a>
        </div>
      </div>
    )}

    {step === 'Fund1' && (
      <>
      {courses?.map((course, idx) => (
        <div className={styles.container} key={idx}>
          <Text
              className={styles.p1}
              align='center'
              size={24}
            >
            {cityTitle(city)} {course.grade}
          </Text>
          <Text align='center' className='eva-color-cinza-700'>
            {`Faça a formação, com certificado, e descubra como usar o material de Ensino Fundamental ${course.grade} para melhorar a aprendizagem dos estudantes.`}
          </Text>
          <div className={styles.buttonContainer}>
            <a
              href={course.url}
              className='eva-btn-solid eva-btn-cinza-700 eva-btn-medium eva-margin-center'
              onClick={() => {
                sendEvent(
                  'Material Educacional',
                  'Click',
                  `Material Educacional: Click - ${step}-${course.grade}-${city}-FACAGRATIS`
                )
              }}
            >
              Faça grátis
            </a>
          </div>
        </div>
      ))}
      </>
    )} 

    {step === 'Fund1' && city === 'São Paulo' && (
      <div className={styles.container}>
        <Text
            className={styles.p1}
            align='center'
            size={24}
          >
            {cityTitle(city)}
        </Text>

        <Text align='center' className='eva-color-cinza-700'>
          Faça a formação, com certificado, e descubra como usar o material de
          Ensino Fundamental para melhorar a aprendizagem dos alunos.
        </Text>

        <div className={styles.buttonContainer}>
          <a
            href={uniqueCourseLink ? uniqueCourseLink : ''}
            className='eva-btn-solid eva-btn-cinza-700 eva-btn-medium eva-margin-center'
            onClick={() => {
              sendEvent(
                'Material Educacional',
                'Click',
                `Material Educacional: Click - ${step}-${city}-FACAGRATIS`
              )
            }}
          >
            Faça grátis
          </a>
        </div>
      </div>
    )}  

    {step === 'Fund2' && (
     <div className={styles.container}>
        <Text
            className={styles.p1}
            align='center'
            size={24}
          >
           Curso online para uso do material Xperience Nova Escola:
        </Text>
        <Text align='center' className='eva-color-cinza-700'>
          Faça a formação com certificado e descubra como usar Xperience Nova Escola para melhorar a aprendizagem dos estudantes.
        </Text>
        <div className={styles.buttonContainer}>
          <a
            href={uniqueCourseLink ? uniqueCourseLink : ''}
            className='eva-btn-solid eva-btn-cinza-700 eva-btn-medium eva-margin-center'
            onClick={() => {
              sendEvent(
                'Material Educacional',
                'Click',
                `Material Educacional: Click - ${step}-XPERIENCE-FACAGRATIS`
              )
            }}
          >
            Faça grátis
          </a>
        </div>
      </div>
    )}      
  </>
  )
}

export default CourseAnnouncement