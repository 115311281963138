import { Location } from 'history'
import { sendPageView } from '../../infrastructure/track/track'
import { MainController } from '../main/main.controller';

export class LocationController {
  public setLocation(location: Location<unknown>) {
    sendPageView(location);
    const { loginController } = MainController.getInstance();
    const searchPart = location.search ? `?${location.search}` : '';
    const redirectUrl = `${location.pathname}${searchPart}`;
    loginController.setRedirectCookie(redirectUrl);
  }
}