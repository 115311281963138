import React from 'react'
import { Text } from 'eva-react'
import styles from './SupporterSection.module.scss'
import ImageSupporter from './ImageSupporter/ImageSupporter'

type Props = React.PropsWithChildren<{
  execution: string[]
  implementation: string[]
  executionSupporters: string[]
  supporters: string[]
}>

const SupporterSection: React.FC<Props> = ({
  execution,
  implementation,
  executionSupporters,
  supporters,
}) => (
  <section className={`container ${styles.supporterSectionContainer}`}>
    <div className={styles.groupExecution}>
      
      <div className={styles.groupSupport}>

        <Text bold className={`${styles.groupTitle}`}>
          Realização
        </Text>

        <div className={`${styles.groupImages}`}>
          {execution.map((img, idx) => (
            <ImageSupporter img={img} key={idx} />
          ))}
        </div>
      </div>

      <Text align='center' color='peixe-boi-dark' bold className={`me-font-42 eva-margin-bottom-4`}>
        Quem também está nessa com a gente
      </Text>

      <div className={styles.groupSupport}>
        <Text bold className={`${styles.groupTitle}`}>
        Implementação
        </Text>
        <div className={`${styles.groupImages}`}>
          {implementation.map((img, idx) => (
            <ImageSupporter img={img} key={idx} />
          ))}
        </div>
      </div>

      <div className={`${styles.groupSupport} ${styles.groupSupporters}`}>
        <Text bold className={`${styles.groupTitle}`}>
          Apoio
        </Text>
        <div className={`${styles.groupImages}`}>
          {supporters.map((img, idx) => (
            <ImageSupporter img={img} key={idx} />
          ))}
        </div>
      </div>

      <div className={styles.groupSupport}>
        <Text bold className={`${styles.groupTitle}`}>
          Parceria
        </Text>
        <div className={`${styles.groupImages}`}>
          {executionSupporters.map((img, idx) => (
            <ImageSupporter img={img} key={idx} />
          ))}
        </div>
      </div>

      <a
        href='https://novaescola.org.br/parcerias-institucional'
        className='eva-btn-solid eva-btn-cinza-700 eva-btn-medium eva-margin-center eva-margin-top-4 eva-margin-bottom-3'
      >
        Conheça os parceiros nova escola
      </a>

    </div>
  </section>
)
export default SupporterSection
