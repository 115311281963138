import React, { useEffect, useState } from 'react'
import Tab from './tab/Tab'
import styles from './TabBar.module.scss'

export type Tab = {
  label: string
  onClick?: () => void
}

type Props = React.PropsWithChildren<{
  testId?: string
  tabs: Tab[]
  initial: number
}>

const TabBar: React.FC<Props> = ({ testId = 'eva', initial, tabs }) => {
  const [selected, setSelected] = useState(0)

  useEffect(() => {
    setSelected(initial)
    return () => {}
  }, [initial, tabs])

  return (
    <div className='container'>
      <div className={styles.container}>
        <div
          className={styles.buttonContainer}
          data-testid={`TabBar-${testId}`}
        >
          {tabs.map((tab, idx) => (
            <Tab
              selected={idx === selected}
              key={idx}
              label={tab.label}
              onClickTab={() => {
                setSelected(idx)
                tab.onClick && tab.onClick()
              }}
            />
          ))}
        </div>
        <span className={styles.bar} />
      </div>
    </div>
  )
}

export default TabBar
