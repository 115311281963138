import React from 'react'
import AudioMedia from '../AudioMedia/AudioMedia'
import styles from './AudioCard.module.scss'
import { Text } from 'eva-react'
import H5AudioPlayer from 'react-h5-audio-player'

type Props = React.PropsWithChildren<{
  title: string
  file: string
  qrcode: string
  onHandlePlay: (media: H5AudioPlayer | null) => void
}>

// eslint-disable-next-line react/display-name
const AudioCard = React.forwardRef<HTMLDivElement, Props>(
  ({ title, file, qrcode, onHandlePlay }, ref) => {
    return (
      <div
        className={`col-12 col-md-4 col-sm-6 ${styles.audioCard}`}
        data-testid='AudioCard'
      >
        <div
          data-testid={'audio-card-' + qrcode}
          ref={ref}
          tabIndex={-1}
          className={`eva-border-peixe-boi-light border-radius-4 ${styles.audioContent}`}
        >
          <div className='eva-margin-bottom-1' style={{ height: '52px' }}>
            <div className='eva-margin-bottom-1'>
              <Text
                color='peixe-boi-dark'
                className={`me-font-20 ${styles.text}`}
              >
                {title}
              </Text>
            </div>
          </div>
          <AudioMedia
            src={file}
            onHandlePlay={onHandlePlay}
          />
        </div>
      </div>
    )
  }
)

export default AudioCard
