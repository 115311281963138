import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styles from './TeachersCommentSkeleton.module.scss'

const TeachersCommentSkeleton: React.FC = () => {
  return (
    <div className={styles.teachersSectionSkeleton} data-testid='teachers-comments-skeleton'>
      <div className={styles.commentContainer}>
        <div className={styles.comment}>
          <Skeleton height={100} width={100} circle={true}></Skeleton>
          <Skeleton className={styles.skeletonTitle}></Skeleton>
          <Skeleton count={10}></Skeleton>
        </div>
      </div>
    </div>
  )
}

export default TeachersCommentSkeleton
