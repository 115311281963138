import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Text, Button } from 'eva-react'
import Header from '../material/Header/Header'
import styles from './LoginOrRegisterScreen.module.scss'
import AuthContext from '../../infrastructure/auth/AuthContext'

type URLParams = {
  contentTitle: string
  region: string
  step: string
}

const LoginOrRegisterScreen: React.FC = () => {
  const { contentTitle } = useParams<URLParams>()
  const { doAuthentication } = useContext(AuthContext)

  useEffect(() => {
    window.scrollTo(0, 0), []
    setTimeout(() => {
      document.body.classList.remove('has-hero')
      // eslint-disable-next-line
      const header = document.querySelector('#ane-main-header')!
      header.classList.add('padding-header')
    }, 100)
  }, [])

  const handleEnd = () => {}

  const handleSuccess = () => {
    window.location.href = "https://www.google.com/";
  }

  return (
    <div className={`container-fluid ${styles.container}`}>
      <div className='container'>
        <div className={`row ${styles.content}`}>
          <div className='col-lg-7 col-md-7 col-sm-12'>
            <Header page='login' title={contentTitle} />
          </div>

          <div className='col-lg-5 col-md-5 col-sm-12'>
            <div className={`${styles.mainCard}`}>
              <div
                tabIndex={-1}
                className={`eva-border-peixe-boi-light border-radius-4 ${styles.mainCardcontent}`}
              >
                <div className='eva-margin-bottom-1'>
                  <div className='eva-margin-bottom-1'>
                    <Text
                      data-testid='TitleId'
                      bold
                      className={`me-font-36 eva-color-cinza-700`}
                    >
                      Cadastre-se para baixar!
                    </Text>
                    <Text className={styles.registerText}>
                      Faça cadastro e acesse todos os materiais{' '}
                      <span className='eva-text-bold'>gratuitamente</span>
                    </Text>
                    <Text bold className={styles.benefitsText}>
                      Quais são os benefícios?
                    </Text>

                    <div
                      tabIndex={-1}
                      className={`eva-border-peixe-boi-light border-radius-4 ${styles.subCardContent}`}
                    >
                      <div className='eva-margin-bottom-1'>
                        <div className='eva-margin-bottom-1'>
                          <div className={styles.tips}>
                            <Text className={styles.texTipOne}>
                              <i className='fas fa-check'></i> Livros da
                              Educação Infantil ao Ensino Fundamental
                            </Text>
                            <Text className={styles.textTipTwo}>
                              <i className='fas fa-check'></i> Conteúdos em .pdf
                            </Text>
                            <Text className={styles.textTipThree}>
                              <i className='fas fa-check'></i> Pronto para usar
                              em sala de aula!
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='eva-margin-top-2'>
                      <Button
                        align='center'
                        color='peixe-boi-dark'
                        full={true}
                        testId='ExtraMaterial'
                        onClick={() =>
                          doAuthentication(
                            handleSuccess,
                            handleEnd
                          )
                        }
                      >
                        Fazer Login ou Cadastro
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginOrRegisterScreen
