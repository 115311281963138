import React from 'react'
import styles from './Hero.module.scss'

const Hero: React.FC = () => {
  return (
    <section className='container-fluid hero eva-bg-me-600'>
      <div className={`${styles.sectionHero} container d-flex`}>
        <div className={styles.blockText}>
          <h1 className={`${styles.title} eva-color-branco eva-text-bold`}>
            Material Educacional
          </h1>
          <h2 className={`${styles.subtitle} eva-color-branco`}>
            Feito por quem ensina, pensado para quem aprende
          </h2>
        </div>
        <div>
          {' '}
          <img
            src={process.env.PUBLIC_URL + '/assets/home/hero.png'}
            width='700'
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
