import React from 'react'
import styles from './ImageSupporter.module.scss'

type Props = {
  img: string
}

const ImageSupporter: React.FC<Props> = ({ img }) => (
  <div className={styles.imageDiv}>
    <img src={img} className={styles.image} />
  </div>
)
export default ImageSupporter
