import React from 'react'
import { getCookie } from '../../infrastructure/cookies/cookies'
import { api } from '../../infrastructure/api'
import AuthContext, { IAuthContext } from './AuthContext'
import { ProfileResponse } from '../../types/Profile'

type Props = React.PropsWithChildren<{
  authURL: string
}>

const authRedirect = () => {
  window.location.assign('https://account.novaescola.org.br/auth/signin')
}

const AuthProvider: React.FC<Props> = ({ children, authURL }) => {
  const doAuthentication = async (
    onAuthSuccess: () => void,
    onAuthEnd: () => void
  ) => {
    const token = getCookie('Bearer')
    if (!token) return authRedirect()
    try {
      const { data } = await api.post<ProfileResponse>(authURL, { token })
      if (data) onAuthSuccess()
    } catch (e) {
      authRedirect()
    } finally {
      onAuthEnd()
    }
  }

  const userIsLoggedIn = async(
    onSuccess: () => void,
    onNotSuccess: () => void
  ) => {
    const token = getCookie('Bearer')
    if(!token) {
      onNotSuccess();
    } else {
      try {
        const { data } = await api.post<ProfileResponse>(authURL, { token })
        if (data){
          onSuccess()
        } else {
          onNotSuccess()
        }
      } catch (error) {
        onNotSuccess()
      }
    }
  }

  const providerValue: IAuthContext = {
    doAuthentication,
    userIsLoggedIn,
  }

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
