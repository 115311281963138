import React from 'react'
import amplitude from 'amplitude-js'
import { Text } from 'eva-react'
import styles from './Header.module.scss'
import image from '../../../assets/imagem-material.png'
import { sendEvent } from '../../../infrastructure/track/track'

const amplitudeDataLayer = 'dataLayerMXP';

interface HeaderProps {
  page?: 'login' | 'any'
  title?: string
}

const vejaComoFuncionaClick = () => {
  amplitude.getInstance().logEvent('Material Educacional: Click - BANNER-INICIO-VEJACOMOFUNCIONA', amplitudeDataLayer);
  sendEvent(
    'Material Educacional',
    'Click',
    'BANNER-INICIO-VEJACOMOFUNCIONA',
  )
}

const Header: React.FC<HeaderProps> = ({ page, title }) => {
  return (
    <div
      className={`${
        page === 'login' ? styles.containerLogin : styles.container
      } hero`}
    >
      <img src={image} className={styles.materialimg}></img>

      <div className={styles.texts}>
        <Text
          color={page === 'login' ? 'peixe-boi-dark' : 'white'}
          bold
          className={`me-font-40`}
        >
          Material Educacional
        </Text>
        {page !== 'login' && (
          <Text color='white' bold className={`me-font-40`}>
            Nova Escola
          </Text>
        )}

        {page !== 'login' && (
          <Text color='white' className={`me-font-30`}>
            Livros gratuitos para o professor e para o aluno: da Educação
            Infantil ao Ensino Fundamental
          </Text>
        )}

        {page !== 'login' && (
          <a
            href='/'
            onClick={vejaComoFuncionaClick}
            className='eva-btn-solid eva-btn-cinza-700 eva-btn-large eva-margin-top-1'
          >
            Veja como funciona
          </a>
        )}

        {page === 'login' && (
          <Text color='peixe-boi-dark' bold className={`me-font-40`}>
            {title}
          </Text>
        )}

        {page == 'login' && (
          <Text className={styles.finalText}>
            Material feito por professores da rede pública, alinhado à BNCC.
            Cadastre-se para fazer o download de forma gratuita
          </Text>
        )}
      </div>
    </div>
  )
}

export default Header
