import React from 'react'
import { sendEvent } from '../../../infrastructure/track/track'
import amplitude from 'amplitude-js'
import styles from './AboutMaterial.module.scss'

const amplitudeDataLayer = 'dataLayerMXP'

const AboutMaterial: React.FC = () => {
  return (
    <section className={`${styles.AboutMaterialContainer} container d-flex`}>
      <div className='d-flex ai-center eva-desktop-only'>
        <img
          src={process.env.PUBLIC_URL + '/assets/home/sobre-o-material.png'}
          width='700'
        />
      </div>
      <div>
        <p className={`${styles.title} eva-color-me-600 eva-text-bold`}>
          Conheça os diferenciais
        </p>
        <p className={styles.subtitle}>Materiais Educacionais Nova Escola</p>
        <ul className={styles.aboutList}>
          <li>
            Livros <span className='eva-text-bold'>gratuitos</span> para
            professores e alunos;
          </li>
          <li>
            Alinhados à <span className='eva-text-bold'>BNCC</span>;
          </li>
          <li>
            Construídos{' '}
            <span className='eva-text-bold'>
              por professores para professores
            </span>
            ;
          </li>
          <li>
            Proposta pedagógica baseada em{' '}
            <span className='eva-text-bold'>metodologias ativas</span>;
          </li>
          <li>
            <span className='eva-text-bold'>Material prático</span> de apoio ao
            planejamento das aulas;
          </li>
          <li>
            Pensados para as{' '}
            <span className='eva-text-bold'>
              necessidades reais da escola pública
            </span>
            ;
          </li>
          <li>
            Coleções especialmente produzidas para Estados parceiros,{' '}
            <span className='eva-text-bold'>
              alinhadas ao currículo e ao contexto locais
            </span>
            .
          </li>
        </ul>
        <a
          href='/downloads/educacao-infantil'
          className='eva-btn-solid eva-btn-cinza-700 eva-btn-medium'
          onClick={() => {
            sendEvent(
              'Material Educacional',
              'Click',
              'LP-MENE-BANNER-DIFERENCIAIS-CONHECA'
            )
            amplitude
              .getInstance()
              .logEvent(
                'Material Educacional: CLICK - LP-MENE-BANNER-DIFERENCIAIS-CONHECA',
                amplitudeDataLayer
              )
          }}
        >
          Conheça todos os materiais
        </a>
      </div>
    </section>
  )
}

export default AboutMaterial
