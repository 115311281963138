import React from 'react'
import styles from './Tab.module.scss'
import css from 'clsx'

type Props = {
  label: string
  onClickTab: () => void
  selected: boolean
}

const Tab: React.FC<Props> = ({ label, onClickTab, selected }) => {
  return (
    <button
      data-testid={`TabButton-${label}`}
      disabled={selected}
      className={css(styles.container, selected && styles.selected)}
      onClick={onClickTab}
    >
      <p
        className={css('eva-color-cinza-700', styles.text, {
          'eva-text-bold': selected
        })}
      >
        {label}
      </p>
    </button>
  )
}

export default Tab
