import React, { useEffect } from 'react'
import useApi from '../../hooks/api/useApi'
import Hero from './Hero/Hero'
import AboutMaterial from './AboutMaterial/AboutMaterial'
import StageCards from './StageCards/StageCards'
import CarouselVersions from './CarouselVersions/CarouselVersions'
import Faq from './Faq/Faq'
import SupporterSection from '../../components/SupporterSection/SupporterSection'
import { Home } from '../../../src/modules/material/types/Material.types'

const HomeScreen: React.FC = () => {
  const { payload, fetchData } = useApi<Home>('get', '/assets/home.json')

  useEffect(() => {
    document.body.classList.add('has-hero')
    // eslint-disable-next-line
    const header = document.querySelector('#ane-main-header')!
    header.classList.remove('padding-header')

    fetchData()
  }, [fetchData])

  return (
    <main role='main'>
      <Hero />
      <AboutMaterial />
      {payload && (
        <>
          <StageCards cardsInformations={payload.tracks} />
          <CarouselVersions carouselInformations={payload.carouselBook} />
          <Faq faqInformations={payload.faqContent} />
          <SupporterSection
            execution={payload?.execution}
            implementation={payload?.implementation}
            supporters={payload?.supporters}
            executionSupporters={payload?.executionSupporters}
  
          />
        </>
      )}
    </main>
  )
}

export default HomeScreen
