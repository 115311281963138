import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function getCookie(cname: string) {
  return cookies.get(cname)
}

export function setCookie(cname: string, value: string, domain = 'novaescola.org.br') {
  cookies.set(cname, value, { path: '/', domain })
}
