import React from 'react'
import styles from './RepeatButton.module.scss'
import { SyntheticEvent } from 'react'

type Props = {
  handleTogglePayer: (e: SyntheticEvent<Element, Event>) => void
  handleMusicRepeat: () => void
}

const RepeatButton: React.FC<Props> = ({
  handleTogglePayer,
  handleMusicRepeat
}) => {
  return (
    <div
      onClick={(e) => {
        handleTogglePayer(e)
        handleMusicRepeat()
      }}
      aria-label='botão repeat'
      className={styles.repeatContainer}
    >
      <i className='fas fa-redo-alt'></i>
    </div>
  )
}

export default RepeatButton
