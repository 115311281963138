import React from 'react'
import { sendEvent } from '../../../infrastructure/track/track'
import amplitude from 'amplitude-js'
import { Text } from 'eva-react'
import { Track } from '../../material/types/Material.types'
import styles from './StageCards.module.scss'

const amplitudeDataLayer = 'dataLayerMXP'

type Props = { cardsInformations: Track[] }

const StageCards: React.FC<Props> = ({ cardsInformations }) => {
  return (
    <section
      className={`${styles.etapasCardsContainer} container-fluid eva-bg-cinza-100`}
    >
      <div className={`${styles.containerWidht} container`}>
        <Text
          bold
          align='center'
          className={`${styles.title} eva-color-cinza-700`}
        >
          Materiais Educacionais por Etapas de Ensino
        </Text>
        <Text
          align='center'
          className={`${styles.subtitle} eva-color-cinza-700 eva-margin-bottom-4`}
        >
          + de 180 conteúdos
        </Text>

        <div className={`${styles.containerCards} d-flex`}>
          {cardsInformations?.map((value, index) => {
            return (
              <a
                aria-label='Ir para'
                href={value.urlCard}
                key={index}
                className={`${styles.card} eva-bg-branco`}
                onClick={() => {
                  sendEvent(
                    'Material Educacional',
                    'Click',
                    `LP-MENE-CARD-ETAPAS-${value.clickEvent}`
                  )
                  amplitude
                    .getInstance()
                    .logEvent(
                      `Material Educacional: CLICK - LP-MENE-CARD-ETAPAS-${value.clickEvent}`,
                      amplitudeDataLayer
                    )
                }}
              >
                <div className={`${styles.cardImg}`}>
                  <img src={value.imageCard} />
                </div>
                <div className={`${styles.cardInfo} eva-text-center`}>
                  <p className='eva-text-bold eva-font-18'>{value.titleCard}</p>
                  <p className={styles.amount}>
                    {value.amountBooks} livros disponíveis
                  </p>
                </div>
              </a>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default StageCards
