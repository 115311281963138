import { Location } from 'history'

const hasGA = () => typeof window.ga === 'function'

export const sendPageView = (location: Location<unknown>) => {
  if (!hasGA()) return console.warn('GA is not set')
  window.ga('set', 'page', location.pathname + location.search)
  window.ga('send', 'pageview')
}

export const sendEvent = (
  eventCategory: string,
  eventAction: 'Click',
  eventLabel?: string
) => {
  if (!hasGA()) return console.warn('GA is not set')
  window.ga('send', 'event', eventCategory, eventAction, eventLabel)
}


